.servicios{
    width: '3px'; 
    height: '6vh';
}

.colorCI{
    background: rgb(255, 128, 0);
}
.colorCR{
    background: rgb(255, 221, 0);
}
.colorLI{
    background: rgb(32, 174, 32);
}
.colorLR{
    background: rgb(20, 162, 139);
}
.docsStatus{
    display: block;
    width: 50px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #000000; 
    padding: 2px;
    font-size: 15px !important;
}