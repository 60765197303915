.selectServicio .customSelect {
  width: 100%;
  line-height: 0em !important;
  height: 40px;
  border: transparent 1px solid !important;
  border-bottom: #303248 1px solid !important;
}

.selectServicio .MuiOutlinedInput-root {
  position: none !important;
}

.selectServicio .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
}

.selectServicio .MuiSvgIcon-root {
  color: black !important;
}
