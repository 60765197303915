.container {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Separación entre filas */
  }
  
  .row_table {
    display: flex;
    align-items: center;
    gap: 13px; /* Espaciado entre celdas */
    padding: 8px;

  }
  
  .text-cell {
    flex: 3; /* Más espacio para el texto */
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .icon.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  